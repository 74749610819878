.App {
  height: 100vh;
}
.MuiSlider-thumb {
  color: #5fb757;
}
.MuiSlider-rail {
  color: lightgreen;
}
.MuiSlider-track {
  color: #5fb757;
}
.MuiSlider-rail {
  height: 10px !important;
}
.MuiSlider-root {
  height: 10px !important;
}
.css-eg0mwd-MuiSlider-thumb {
  width: 25px !important;
  height: 25px !important;
}
.bg-footer {
  background: url("/public/img/bg-tile-2-1.webp");
  background-repeat: repeat;
  background-size: auto 100%;
}

@media screen and (max-width: 330px) {
  .form-control {
    width: 220px !important;
  }
  .react-tel-input {
    width: auto !important;
  }
}
